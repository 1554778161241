import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import images from '../constants/images';
import { Link } from 'react-router-dom';
// import { useGlobalState } from '../GlobalStateContext';


const data = [
  
    {
      id: 1,
      name: "Unlock Global Opportunities with the Pannónia Scholarship Program!",
    },
    {
      id: 2,
      name: "Why apply for the Pannónia Scholarship?",
    },
    {
      id: 3,
      name: "Who is Eligible for the Pannónia Scholarship Program?",
    },
    {
      id: 4,
      name: "Check If Your University is on the List!",
    },
    {
      id: 5,
      name: "More Information About the Pannónia Scholarship",
    },
    
  ];
const PannoniaProgram = () => {
    const{t} = useTranslation();
    
        
      const location = useLocation();
      const cardDetails = location.state?.cardDetails || {};
      const navigate = useNavigate();
      // const handleApply = () => {
      //   navigate("/contactus");
      // };

      const [navHeight, setNavHeight] = useState(0);
      useEffect(() => {
        const navElement = document.querySelector('.pro_nav_main_wrapp');
        if (navElement) {
          const height = navElement.offsetHeight;
          setNavHeight(height);
        }
      }, []);

    const [getindex, setIndex] = useState(0);
  //   const handleLinkClick = (link, index) => {
  //     setIndex(index);
  
  //     // Scroll to the corresponding section
  //     const element = document.getElementById(link);
  //     if (element) {
  //       // Get the height of the pro_nav_main_wrapp element
  //       const proNavHeight = document.querySelector('.pro_nav_main_wrapp').offsetHeight;
  
  //       // Define an additional offset of 92px for the main nav
  //       const additionalOffset = 82;
  
  //       // Calculate the scroll position with both offsets
  //       const y = element.getBoundingClientRect().top + window.pageYOffset - proNavHeight - additionalOffset;
  
  //       // Scroll to the calculated position
  //       window.scrollTo({
  //         top: y,
  //         behavior: 'instant' // Smooth scroll for a better experience
  //       });
  //     }
  // };

    const navigate1 = useNavigate();
    // const handleCardClick1 = () => {
    //     // Navigate to the desired route
    //     navigate1('/InternshipUSA');
    //   };
    //   const handleCardClick2 = () => {
    //     // Navigate1 to the desired route
    //     navigate1('/ProfessionalCareerTrainingUSA');
    //   };
    //   const handleCardClick3 = () => {
    //     // Navigate1 to the desired route
    //     navigate1('/VirtualInternship');
    //   };
    //   const handleCardClick4 = () => {
    //     // Navigate1 to the desired route
    //     navigate1('/STEPUSA');
    //   };
    //   const handleCardClick5 = () => {
    //     // Navigate1 to the desired route
    //     navigate1('/AuPairProgram');
    //   };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);
  
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sections = document.querySelectorAll('.programdes_wrap');
  //     let currentIndex = 0;
  //     const scrollPosition = window.scrollY;

  //     const navbarHeight = 84.2; // Adjust the selector as per your actual class name
  //     const proNavBaseHeight = document.querySelector('.pro_nav_base_wrapp').offsetHeight;
    
  //     // Calculate the total offset
  //     const yOffset = navbarHeight + proNavBaseHeight;
  
  //     sections.forEach((section, index) => {
  //       const sectionTop = section.offsetTop - yOffset; // Adjusted offset for better UI
  //       const sectionBottom = sectionTop + section.offsetHeight;
  
  //       if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
  //         currentIndex = index;
  //       }
  //     });
  
  //     setIndex(currentIndex);
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
    
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);


//   const { setScrollPosition } = useGlobalState();
//   const handleApplyNowClick = () => {
//     setScrollPosition(1);
//   };
  return (
    <div>
        <div>
            <div className="programP_main_wrapp">
           {/* <img src={images.program_banner2} alt="" /> */}
                <div className='programP-title'>
                    <p>{t("Pannónia Scholarship Program")}</p>
                </div>
            </div>
        </div>
        {/* <div className="pro_nav_main_wrapp">
          <div className="container">
            <div className="pro_nav_base_wrapp">
              <div className="pro_nav_links_pannonia">
                {data && data.length > 0
                  ? data.map((item, index) => {
                      return (
                        <button
                          className={getindex === index ? "selected-link" : ""}
                          onClick={() => handleLinkClick(item.id, index)}
                        >
                        {t(item.name)}
                        </button>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div> */}
        <div id='programdescription'>
            <div className="programdes-main">
                <div className="programdes_wrap" id="1">
                    <p className="pro_des_title">{t("Unlock Global Opportunities with the Pannónia Scholarship Program!")}</p>
                    <br />
                    <hr className="head-bottom" />
                    <br />
                    <div className="pro_des_content">
                        <p>
                          <strong>{t("The Pannónia Scholarship Program")}</strong> {t(" opens the door to exciting international experiences.")}
                        </p>
                        <br />
                        <p>
                          {t("Both short and long-term mobility opportunities are offered for Hungarian higher education students and fresh graduates")}<strong> {t("to study, research,")}</strong> {t("or")} <strong>{t("do internships abroad")}</strong>{t(" in every country of the world.")}
                        </p>
                        <br />
                        <p>
                            <strong>{t("Financial Support for Your U.S. Internship Fees:")}</strong> {t("Yes, if you are a student or a fresh graduate you can use the scholarship to cover the participation fees of your U.S. Internship Program!")}
                        </p>
                        <br />
                        <p>
                            {t("If your university is part of the program, we’ll connect you with the program administrator to guide you through every step.")}
                        </p>
                        <br />
                        <p>
                            <strong>{t("Why wait? Let us help you get started!")}</strong>
                        </p>
                    </div>
                    {/* <div className='program-webinar-btn'>
            <button
              onClick={() => window.open("https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=webinarbutton", "_blank")}
            >Register Now </button>
          </div> */}
                </div>
                <div className="programdes_wrap" id="2">
                    <p className="pro_des_title">{t("Why Apply for the Pannónia Scholarship?")}</p>
                    <br />
                    <hr className="head-bottom" />
                    <br />
                    <div className="pro_des_content">
                    <p>
                    <strong>{t("The Pannónia Scholarship")}</strong>{t(" can provide you with financial support to make your dreams come true.")}
                    </p>
                    </div>
                    <br />
                    <div className="eligibility-list">
                    {/* {eligibility.map((list) => { */}
                        {/* return ( */}
                        <React.Fragment>
                            <li className='eligibility-list-list1'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("pannonia_scholarship") }} />
                            </li>
                            <li className='eligibility-list-list1'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p><strong>{t("Just 4 months of scholarship support")}</strong>{t(" can cover the entire Internship Program fee! If you get the scholarship for longer than 4 months, even better, you can use the funds to visit the places in the USA that you always dreamed about!")}
                            </p>
                            </li>
                            <li className='eligibility-list-list1'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("no_lost_credit") }} />
                            </li>
                        </React.Fragment>
                        {/* ); */}
                    {/* })} */}
                    </div>
                </div>
                <div className="programdes_wrap" id="3">
                    <p className="pro_des_title">{t("Who is Eligible for the Pannónia Scholarship Program?")}</p>
                    <br />
                    <hr className="head-bottom" />
                    <br />
                    <div className='panno-pro-list'>
                      <ul>
                        <li>
                          <strong>{t("Hungarian Higher Education Students:")}</strong>
                        </li>
                        <div className="fee-list">
                          <ul>
                            <li>
                              {t("Enrolled in undergraduate, master’s, or doctoral programs at a Hungarian university.")}
                            </li>
                            <li>
                              {t("Students who wish to study, research, or intern abroad as part of their academic journey.")}
                            </li>
                          </ul>
                        </div>
                        <li>
                        <strong>{t("Fresh Graduates:")}</strong>
                        </li>
                        <div className="pro_des_content">
                          <p>
                              {t("Recently completed your degree? No problem! The program supports fresh graduates seeking internships abroad within one year of graduation.")}
                              <br />
                          </p>
                        </div>
                      </ul>
                    </div>
                    
                </div>
                <div className="programdes_wrap" id="4">
                    <p className="pro_des_title">{t("Check if your University is on the List!")}</p>
                    <br />
                    <hr className="head-bottom" />
                    <br />
                    <div className="pro_des_content">
                    <p>
                        {t("Click")} <Link to={"https://pannoniaosztondij.hu/a-pannonia-osztondijprogram-intezmenyi-koordinatorainak-elerhetosege"} target='_blank' className='blue-link-acc' style={{textDecoration:"underline"}}>{t("here")}</Link>&nbsp;{t("to see the full list of universities participating in the Pannónia Scholarship Program.")}</p>
                
                    </div>
                </div>
                <div className="programdes_wrap" id="5">
                    <p className="pro_des_title">{t("More Information About the Pannónia Scholarship")}</p>
                    <br />
                    <hr className="head-bottom" />
                    <br />
                    <div className="pro_des_content">
                    <p>
                      <Link to={"https://pannoniaosztondij.hu/about-the-pannonia-scholarship-programme "} target='_blank' className='blue-link-acc' style={{textDecoration:"underline"}}>https://pannoniaosztondij.hu/about-the-pannonia-scholarship-programme </Link>
                    </p>
                    </div>
                </div>
                <div className="apply-btn-div">
                    {/* <Link 
                    //  to="/contactus" 
                    //  state={{scroll: 'true'}}
                    //  onClick={handleApplyNowClick}
                    to={""}
                    target='_blank'
                    className="apply-btn-new"
                    >
                    {t(" Book your online consulation")}
                    </Link> */}
                    <button onClick={() => window.open("https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=pannonia", "_blank")}>
                      {t("Register for our Webinar")}
                    </button>
                </div>
            </div>
        </div>    
    </div>
  )
}

export default PannoniaProgram