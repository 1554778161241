import React, { useEffect, useRef, useState } from 'react'
import Homebanner from '../container/homeBanner/Homebanner'
import Homecultural from '../container/homeCulturalprgm/Homecultural'
import Homeplacement from '../container/homePlacement/Homeplacement'
import HomeAbout from '../container/HomeAbout/HomeAbout'
import HomeGallery from '../container/HomeGallery/HomeGallery'
import Joinstudents from '../container/homeJoinStudents/Joinstudents'
import Homereview from '../container/homeReview/Homereview'
import Homeblog from '../container/HomeBlog/Homeblog'
import HomeQuestions from '../container/HomeQuestions/HomeQuestions'
import Webinar from '../container/webinar/Webinar'
import { useLocation } from 'react-router-dom'
import { useGlobalState } from '../GlobalStateContext'

const Home = () => {
  const sectionRef = useRef(null);
  const { setScrollPosition, scrollPosition } = useGlobalState();
  const [isWebinarVisible, setIsWebinarVisible] = useState(false);


  useEffect(() => {
    if (scrollPosition === 1 && sectionRef.current) {
      const offset = 30; // Adjust for better positioning
      const topPosition = sectionRef.current.getBoundingClientRect().top + window.scrollY - offset;
      
      window.scrollTo({ top: topPosition, behavior: "smooth" });
      setScrollPosition(0); // Reset to prevent repeated scrolls
    }
  }, [scrollPosition]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsWebinarVisible(entry.isIntersecting);
      },
      { threshold: 0.3 } // Adjust to detect when at least 30% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);
  

  return (
    <div>
        <Homebanner/>
        <Homecultural/>
        <div ref={sectionRef} className='webinar-scroll' id="webinar-section">
        <Webinar scrollPosition={scrollPosition}/>
        </div>
        <Homeplacement/>
        <HomeAbout/>
        <HomeGallery/>
        <Joinstudents/>
        <Homereview/>
        <Homeblog/>
        <HomeQuestions/>
        {/* <Homegetintouch /> */}
    </div>
  )
}

export default Home