import React from 'react'
import "./taxreturndetails.css"
import images from '../../constants/images';
import { useTranslation } from "react-i18next";

const TaxreturnDetails = () =>{
    const{t} = useTranslation();
  return (
    <div className='tax_main_wrapp'>
        <div className="tax_first_blue_wrapp">
          <h1>Navigating J-1 Visa Taxes: A Complete Guide To Filing Your U.S. Tax Return</h1>
        </div>

        <div className="tax_base_wrapp">
            <p className="tax_des_title">{t("How do I file a Tax Return?")}</p>
            <br />
            <hr className="head-bottom" />
            <br />
            <div className="pro_des_content">
                <p>
                {t("You will require a specialized tax return form rather than a standard one.")}
                </p>
                <br />
                <p>
                {t("Rely on our trusted partner")}<a className='green-text-new' href='https://taxreturn4you.com/apply-now?r=oHDvH0' target='_blank'>TaxReturn4You</a>,{t("to ensure that your tax refund application is legally compliant and that you receive your maximum tax refund.")}
                </p>
                <br />
                <p>
                {t("Get your tax refund in 5 easy steps:")}
                </p>
            </div>
            <div className='tax_first_list'>
                <ul>
                    <li>
                        1.    {t("Submit Form with TaxReturn4You:")}<br/>
                        <p>
                        {t("Submit your documents and answer some simple questions:")} <a className='green-text-new' href='https://taxreturn4you.com/apply-now?r=oHDvH0' target='_blank'>https://taxreturn4you.com/apply-now?r=oHDvH0</a>
                        </p>
                    </li>
                    <li>
                        2.    {t("Review Documents")}<br/>
                        <p>
                         {t("TaxReturn4You reviews and processes your information and does the tax calculation.")}
                        </p>
                    </li>
                    <li>
                        3.    {t("Sending Request")}<br/>
                        <p>
                        {t("TaxReturn4You e-files or mails your tax return to tax authorities.")}
                        </p>
                    </li>
                    <li>
                        4.    {t("IRS Review")}<br/>
                        <p>
                        {t("IRS and other tax authorities review your tax return.")}
                        </p>
                    </li>
                    <li>
                        5.    {t("Wait for Money")}<br/>
                        <p>
                        {t("You receive your tax refund.")}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div className="tax_base_wrapp">
            <p className="tax_des_title">{t("What Taxes Do J1 Interns & Trainees Need To Pay In The U.S.?")}</p>
            <br />
            <hr className="head-bottom" />
            <br />
            <p className='tax-description'>
            {t("As a foreign visitor receiving paid training on a J-1 visa, you are obligated to pay certain, but not all, taxes that U.S. citizens pay. Typically, about 10% to 15% of your salary is withheld for federal income tax. Additionally, some states and cities impose income taxes, ranging from an extra 5% to 8%. While your host organization should handle the tax withholding accurately, it's advisable to check your initial paycheck for any errors and ensure the tax withholding is correct.")}
            </p>
            <div className="what-you-pay-list">
                <p className='tax-list-head'>{t("What you must pay:")}</p>
                <ul>
                    <li>
                    <img src={images.list_icon} alt="Icon 1"  className='list-icon'/>
                    <p>{t("Federal income tax")}</p>
                    </li>
                    <li>
                    <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                    <p>{t("State income tax (if applicable)")}</p>
                    </li>
                    <li>
                    <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                    <p>{t("Local or city income tax (if applicable)")}</p>
                    </li>
                </ul>

                <p className='tax-list-head'>{t("What you do not have to pay:")}</p>
                <ul>
                    <li>
                    <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                    <p>{t("Social Security and Medicare tax (FICA)")}</p>
                    </li>
                    <li>
                    <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                    <p>{t("Federal unemployment tax (FUTA)")}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div className='tax_base_wrapp'>
            <img src={images.tax_img2} alt="tax-ig" className='tax_img2'/>
        </div>

        <div className="tax_base_wrapp">
            <p className="tax_des_title">{t("What Is a Tax Return?")}</p>
            <br />
            <hr className="head-bottom" />
            <br />
            <div className="pro_des_content">
                <p>
                {t("Every paycheck includes a deduction meant to cover your anticipated tax obligations to both the state and federal governments. However, this estimated amount may not always be precise. It's important to review your financial records to determine whether you've overpaid, paid the correct amount, or underpaid. This evaluation, along with the information you provide to the U.S. and state authorities, constitutes your tax return.")}
                </p>
                <br />
            </div>
        </div>  
        <div className="tax_base_wrapp">
            <p className="tax_des_title">{t("When Is The Deadline?")}</p>
            <br />
            <hr className="head-bottom" />
            <br />
            <div className="pro_des_content">
                <p>
                {t("Each year, tax returns must be filed by APRIL 15th, also known as Tax Day!")}
                </p>
                <br />
            </div>
        </div>    
    </div>
  )
}

export default TaxreturnDetails