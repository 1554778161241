import React from 'react'
import "./webinar.css"
import { useTranslation } from 'react-i18next';
import images from '../../constants/images';

const Webinar = () => {
    const{t} = useTranslation();
  return (
    <div className='home-section3'> 
        <div className='h-webinar-div'>
            <div className='home-sec3-title'>
                <p className='h-secc3-title1'>{t('WEBINAR')}</p>
                <p className='h-secc3-title2-web' dangerouslySetInnerHTML={{ __html: t("Join Our Free Webinar") }} />
            </div>
            <div className='h-webinar-img'>
                <img src={images.webinar_banner} alt="" />
            </div>
            <div className='home-web-content'>
                <h3>{t("What You’ll Gain:")}</h3>
                <div>
                    <React.Fragment>
                        <li className='webinar-list'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("Comprehensive Guidance:")}}/>
                        </li>
                        <li className='webinar-list'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("Career Insights:")}}
                            />
                        </li>
                        <li className='webinar-list'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("Travel & Adventure:")}} />
                        </li>
                        <li className='webinar-list'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("Expert Advice:")}} />
                        </li>
                        <li className='webinar-list'>
                            <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                            <p dangerouslySetInnerHTML={{ __html: t("Q&A Session:")}} />
                        </li>
                    </React.Fragment>
                </div>
                <h4>{t("Don’t miss this opportunity to elevate your career, explore a new culture, and embark on an unforgettable journey.")}</h4>
                <div className='home-web-btn'>
                    <button
                    onClick={() => window.open("https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=webinarbutton", "_blank")}
                    >{t("Register Now")}</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Webinar